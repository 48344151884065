@font-face {
    font-family: 'Azonix';
    src: url('assets/fonts/AzonixRegular.woff2') format('woff2'),
        url('assets/fonts/AzonixRegular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('assets/fonts/Poppins-Light.woff2') format('woff2'),
        url('assets/fonts/Poppins-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('assets/fonts/Poppins-Regular.woff2') format('woff2'),
        url('assets/fonts/Poppins-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

html {
    scroll-behavior: smooth;
}

body {
    margin: 0;
    font-family: 'Azonix';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-x: hidden;
    background: #040517;
    color: #fff;
}

code {
    font-family: 'Azonix';
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'Azonix';
}

p {
    line-height: 1.5;
    font-family: 'Poppins';
}

.button-primary {
    display: inline-block;
    padding: 15px 58px;
    color: #fff;
    border-radius: 4px;
    border: 1px solid #464646;
    background: linear-gradient(45deg, #C0429B, #123A95);
    text-decoration: none;
}

.header-menu {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    width: 100%;
    border-bottom: 1px solid rgba(255,255,255,0.2);
    backdrop-filter: blur(15px);
}

.header-menu .nav-link {
    margin: 0 20px;
    color: #fff;
}

footer {
    border-top: 1px solid #252525;
    padding-top: 31px;
}

.bottom-footer {
    margin-top: 31px;
    padding: 16px 0;
    border-top: 1px solid #252525;
}

.bottom-footer p {
    color: #fff;
    opacity: .6;
    font-size: 12px;
}

.footer-links-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.footer-links-list a {
    color: #fff;
    font-size: 18px;
    text-decoration: none;

}

.text-md-light {
    color: #fff;
    opacity: .6;
}

.footer-links-list li:not(:last-child) {
    margin-bottom: 18px;
}

.section-title-area {
    position: relative;
    z-index: 3;
}

.section-title-area.shadow-space {
    padding-bottom: 150px;
}

.section-title-area.small-shadow-space {
    padding-bottom: 100px;
}


.gradientText {
    font-size: 39px;
    background: linear-gradient(106.21deg, #FFFFFF 1.83%, #FF59CE 30.45%, #477FFF 62.68%, #FFFFFF 97.25%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.shadow-text {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    font-size: 128px;
    color: #040517;
    text-shadow:
        -1px -1px 0 rgba(255, 255, 255, 0.1),
        1px -1px 0 rgba(255, 255, 255, 0.1),
        -1px 1px 0 rgba(255, 255, 255, 0.1),
        1px 1px 0 rgba(255, 255, 255, 0.1);
    z-index: -1;
    opacity: 0.6;
    pointer-events: none;
}

.banner-area {
    position: relative;
    z-index: 10;
    padding: 300px 0 100px;
    height: 100vh;
}

.banner-img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top;
    z-index: -1;
}

.banner-tr-bg {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
}

.btn-gradient-bg {
    background: transparent;
    border: none;
    position: relative;
    display: inline-block;
    z-index: 3;
    padding: 19px 50px 13px;
    color: #fff;
    line-height: 20px;
    text-decoration: none;
}

.btn-gradient-bg img {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    z-index: -1;
}

.btn-gradient-bg:hover {
    transition: all .3s ease-in-out;
    transform: translateY(-4px) !important;
}

.bordered-btn-bg {
    display: inline-block;
    background: transparent;
    border: none;
    position: relative;
    z-index: 3;
    padding: 17px 44px;
    color: #fff;
    line-height: 20px;
    text-decoration: none;
}

.bordered-btn-bg img {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    z-index: -1;
}

.bordered-btn-bg:hover {
    transform: translateY(-4px) !important;
    transition: all .3s ease-in-out;
}

.banner-desc {
    font-size: 26px;
    align-items: center;
}

.banner-gambet-title {
    width: 450px;
}

.gambet-row-wrapper {
    position: relative;
    margin-top: -100px;
    z-index: 3;
}

.gambet-card {
    padding: 72px 0 60px;
    text-align: center;
    background: linear-gradient(to bottom, #0E0F20 0%, rgba(28, 58, 149, 0.24) 80%, rgba(184, 65, 155, 0.3) 100%);
    overflow: hidden;
    border-radius: 32px;
    height: 400px;
    cursor: pointer;
}

.gambet-card:hover {
    background: linear-gradient(to bottom, #0E0F20 0%, rgba(28, 58, 149, 1) 80%, rgba(184, 65, 155, 1) 100%);
}

.gambet-card h4 {
    font-size: 20px;
    margin-bottom: 0;
    height: 55px;
}

.gambet-card img {
    width: 100%;
    transition: all .3s ease-in-out;

}

.gambet-top-space {
    margin-top: 150px;
}

.gambet-area {
    padding-top: 100px;
    padding-bottom: 34px;
}

.gambet-card:hover img {
    transform: scale(1.2);
}

.section-title-area h2 {
    font-size: 46px;
    text-transform: capitalize;
}

.grid-odds {
    padding-top: 40px;
    padding-bottom: 80px;
}

.odds-card {
    background: linear-gradient(89.08deg, rgba(255, 255, 255, 0.08) 0.73%, rgba(255, 255, 255, 0) 99.27%);
    padding-left: 30px;
    padding-top: 15px;
    padding-bottom: 15px;
    display: flex;
    align-items: center;
    border-radius: 16px;
    height: 100%;
}

.odds-card h4 {
    background: linear-gradient(106.21deg, #FFFFFF 1.83%, #FF59CE 30.45%, #477FFF 62.68%, #FFFFFF 97.25%);
    font-size: 30px;
    background: linear-gradient(106.21deg, #FFFFFF 1.83%, #FF59CE 30.45%, #477FFF 62.68%, #FFFFFF 97.25%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.olds-content {
    margin-right: 30px;
    flex: 1;
    font-family: 'poppins';
    font-weight: 400;
}

.odds-card img {
    width: 175px;
}

.section-title-area.shadow-centered {
    padding: 32px 0;
}

.shadow-centered .shadow-text {
    left: 0;
    transform: none;
}

.shadow-text.small-shadow {
    font-size: 100px;
}

.roadmap-card {
    background: linear-gradient(270deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.06) 100%);
    padding: 40px 30px;
    padding-right: 10px;
    position: relative;
    z-index: 3;
    height: 348px;
}

.roadmap-area {
    height: 100vh;
    padding-top: 110px;
}

.roadmap-card h4.gradientText {
    font-size: 30px;
}

.roadmap-card .roadmap-stick {
    position: absolute;
    left: -6px;
    top: 42%;
}

.roadmap-area-list .col-lg-3:nth-child(4) .roadmap-card {
    margin-top: -79px;
}

.roadmap-area-list .col-lg-3:nth-child(4) .roadmap-card .roadmap-stick {
    top: 65%;
}

.roadmap-area-list .col-lg-3:nth-child(3) .roadmap-card {
    margin-top: 40px;
}

.roadmap-area-list .col-lg-3:nth-child(3) .roadmap-card .roadmap-stick {
    top: 52%;
}

.roadmap-area-list .col-lg-3:nth-child(2) .roadmap-card {
    margin-top: 80px;
}

.roadmap-area-list .col-lg-3:nth-child(2) .roadmap-card .roadmap-stick {
    top: 50%;
}

.roadmap-area-list {
    margin-top: -100px;
}

.roadmap-area-list .col-lg-3:nth-child(1) .roadmap-card {
    margin-top: 100px;
}
.roadmap-road {
    position: absolute;
    bottom: -124px;
    left: 12px;
}

.footer-sec-area {
    padding-top: 100px;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.social-share-buttons {
    display: flex;
    flex-wrap: wrap;
    list-style-type: none;
    padding: 0;
    margin: 0;
    margin-top: 24px;
    margin-bottom: 48px;
}

.footer-image {
    width: 45%;
    margin: 0 auto;
    margin-bottom: 20px;
}

.social-share-buttons li {
    flex: 1;
}

.social-share-buttons li a {
    width: 100%;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.05) 0%, rgba(255, 255, 255, 0) 100%);
    /* padding: 32px 0; */
    color: #fff;
    text-decoration: none;
    display: inline-block;
    /* border-bottom: 1px solid rgba(255, 255, 255, 0.16); */
    /* transition: all .3s ease-in-out; */
}

/* .social-share-buttons li a:hover {
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.05) 0%, rgba(255, 255, 255, 0) 100%), linear-gradient(265.1deg, #C0429B 0%, #123A95 97.12%);
} */
/* .social-share-buttons li:not(:last-child) a {
    border-right: 1px solid rgba(255, 255, 255, 0.16);

} */

.faq-area {
    padding: 80px 0 0;
}

.faq-area .section-title-area {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.faq-area .section-title-area img {
    width: 120px;
    height: auto;
    margin-left: 48px;
}

.accordion-item {
    background: transparent;
    border: none;
    margin-bottom: 15px;
}

.accordion-item .accordion-header .accordion-button {
    background: transparent;
    box-shadow: none;
    border-bottom: 1px solid rgba(255, 255, 255,0.2);
    color: #fff;
    padding-left: 0;
}
.accordion-item .accordion-header .accordion-button:not(.collapsed){
    border-bottom: 1px solid rgba(255, 255, 255,1);
}

.accordion-button.collapsed {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.accordion-button:not(.collapsed)::after,
.accordion-button::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.accordion-body {
    border: none;
    color: rgba(255, 255, 255, 0.5);
    padding-left: 0;
    padding-right: 0;
    font-family: 'poppins';
}

.defi-area {
    padding-top: 100px;
}

.faq-title .gradientText {
    font-size: 32px;
}

.def-sec-box {
    display: flex;
    flex-direction: column;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.06) 0%, rgba(255, 255, 255, 0) 100%);
    padding: 60px 60px 40px 8%;
    width: 50%;
    border-top-right-radius: 32px;
    border-bottom-right-radius: 32px;
    backdrop-filter: blur(52px);
}

.def-image {
    position: absolute;
    right: 16%;
    bottom: -135px;
    width: 42%;
    height: auto;
    mix-blend-mode: lighten;
}

.def-shadow {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 38%;
    height: auto;
    pointer-events: none;
}

.shapes-title-area {
    display: flex;
    align-items: color;
    justify-content: space-between;
    gap: 20px;

}

.shapes-title-area img {
    margin-top: 17px;
}

.client-logos-area {
    padding: 50px 0 100px;
}

.client-logo {
    text-align: center;
}

.client-logo img {
    width: auto;
    height: 40px;
}

.howwe-area {
    padding-top: 100px;
}

.roadmap-shadow {
    position: absolute;
    top: -50%;
    left: 0;
    z-index: 0;
    width: 400px;
    height: auto;
    pointer-events: none;
}

.image-gembet-raf {
    z-index: 3;
    margin-top: -80px;
    box-shadow: 0px 18.049999237060547px 46.929996490478516px 0px rgba(0, 0, 0, 0.25);
}

.image-fidd {
    width: 80%;
}

.diff-grad-color {
    margin-top: 20px;
    background: linear-gradient(106.21deg, #FFFFFF 1.83%, #FF59CE 30.45%, #477FFF 62.68%, #FFFFFF 97.25%);
    font-size: 26px;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.community-area {
    padding-top: 100px;
    height: 100vh;
}

.cominity-content-wrapper {
    margin-top: -50px;
}

.cummunity-image {
    position: absolute;
    top: 50px;
    left: -66px;
    width: 380px;
}

.cm-layout {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;
    position: relative;
    margin-top: 100px;

}

.cm-box-1 {
    background: linear-gradient(90deg, rgba(255, 255, 255, 0.08) 0%, rgba(255, 255, 255, 0) 100%);
    padding: 32px;
    border-radius: 24px;
    width: 67%;
    margin-right: 136px;
}

.cm-box-2 {
    background: linear-gradient(90deg, rgba(255, 255, 255, 0.08) 0%, rgba(255, 255, 255, 0) 100%);
    padding: 32px;
    border-radius: 24px;
    width: 61%;
    margin-right: 100px;
}

.cm-box-3 {
    background: linear-gradient(90deg, rgba(255, 255, 255, 0.08) 0%, rgba(255, 255, 255, 0) 100%);
    padding: 32px;
    border-radius: 24px;
    width: 64%;
    margin-right: 0;
}

.cm-title {
    background: linear-gradient(106.21deg, #FFFFFF 1.83%, #FF59CE 30.45%, #477FFF 62.68%, #FFFFFF 97.25%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    float: right;
    position: absolute;
    bottom: 26px;
    left: -60px;
    width: 377px;
    font-size: 42px;
}

.Tokenomics-area,.totalspply-area {
    padding-top: 100px;
    padding-bottom: 60px;
}

.Tokenomics-area img {
    margin-top: 5rem;
}

.banner-person {
    position: absolute;
    right: 5%;
    bottom: 0;
    width: 50%;
}

.cubeshape {
    width: 100%;
    text-align: center;
    margin: 0 auto;
    height: 90px;
    text-transform: uppercase;
    font-family: sans-serif;
}

.cubeshape h1 {
    font-size: 20px;
    font-weight: lighter;
}


.cubeshape h1 a {
    padding: 28px 0px;
}

.cubeshape a {
    position: absolute;
    top: 0;
    left: 0;
}

.toggle-button {
    display: inline-block;
    vertical-align: middle;
    width: 100%;
}

.cube {
    -webkit-transition: -webkit-transform .33s;
    transition: transform .33s;
    /* Animate the transform properties */
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
}


.first_cube,
.second_cube {
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.05) 0%, rgba(255, 255, 255, 0) 100%);
    height: 90px;
    width: 100%;
    position: relative;
}

.first_cube {
    /* border-bottom: 4px solid #ccc; */
    -webkit-transform: translateZ(30px);
    transform: translateZ(30px);
}

.second_cube {
    /* border-top: 4px solid #ccc; */
    margin-top: -2px;
    -webkit-transform: rotateX(-90deg) translateZ(-30px);
    transform: rotateX(-90deg) translateZ(-30px);
}
.social-share-buttons li .second_cube a{
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.05) 0%, rgba(255, 255, 255, 0) 100%),
linear-gradient(265.1deg, #C0429B 0%, #123A95 97.12%);

}
.cube:hover {
    -webkit-transform: rotateX(90deg);
    transform: rotateX(90deg);

}


.small-reverse-card.odds-card {
    margin-bottom: 77px;
    flex-direction: row-reverse;
    height: 200px;

}
.small-reverse-card.odds-card.right {
    background: linear-gradient(-89.08deg, rgba(255, 255, 255, 0.08) 0.73%, rgba(255, 255, 255, 0) 99.27%)
}
.small-reverse-card.odds-card img{
    width: 120px;
    margin-right: 10px;
}
.small-reverse-card.odds-cardh4{
    font-size: 22px;
}
.whatgambet-area{
    padding-top: 100px;
}
.filter-shadow{
    mix-blend-mode: lighten;
}

.centerd-what-image{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    margin-top: -13%;
}

.whatgambet-area {
    position: relative;
    z-index: 8;
}
.news-card{
    padding: 16px;
    background: linear-gradient(89.08deg, rgba(255, 255, 255, 0.08) 0.73%, rgba(255, 255, 255, 0) 99.27%);
    border-radius: 16px;
    height: 100%;
}
.news-card img{
    width: 100%;
    margin-bottom: 24px;
}
.news-card h3{
    background: linear-gradient(106.21deg, #FFFFFF 1.83%, #FF59CE 30.45%, #477FFF 62.68%, #FFFFFF 97.25%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-bottom: 16px;
    font-size: 24px;
}

.ref-btn{
    display: inline-flex;
    width: 64px;
    height: 64px;
    border: 1px solid #FFFFFF1A;
    color: #fff;
    background: linear-gradient(to right, rgba(255, 255, 255, 0.06) 0%, rgba(255, 255, 255, 0) 100%);
    border-radius: 50%;
    align-items: center;
    justify-content: center;
}
.ref-btn.prev{
}
@media (min-width: 2000px) {
    .banner-person {
        position: absolute;
        right: 12%;
        bottom: 0;
        width: 44%;
    }
}

@media (min-width: 1601px) {
    .gambet-card{
        height: 470px;
    }
    .gambet-row-wrapper {
        margin-top: -48px;
    }
    .col-xxxl-11 {
        flex: 0 0 auto;
        width: 91.66666667%;
    }
    .image-gembet-raf{
        margin-top: 0;
    }
    .cm-layout {
        margin-top: 300px;
    }
    .cummunity-image {
        position: absolute;
        top: 120px;
        left: -53px;
        width: 487px;
    }
    .def-sec-box{
        padding: 95px 70px 44px 16.5%;
    }
    .def-image {
        position: absolute;
        right: 16%;
        bottom: -277px;
        width: 41%;
        height: auto;
        mix-blend-mode: lighten;
    }
    .def-sec-box h2{
        margin-bottom: 36px;
    }
    .faq-title .gradientText {
        font-size: 38px;
    }   
    .roadmap-area{
        display: flex;
        align-items: center;
        padding: 0;
        padding-bottom: 80px;
    } 
}
@media (max-width: 1440px) {
    .banner-person {
        position: absolute;
        right: 3%;
        bottom: 0;
        width: 53%;
        z-index: -1;
    }
    .gambet-card{
        height: 440px;
    }
    .cummunity-image {
        position: absolute;
        top: 50px;
        left: 16px;
        width: 380px;
    }
    .cominity-content-wrapper {
        margin-top: 0;
    }
    .roadmap-road {
        position: absolute;
        bottom: -123px;
        left: 8px;
    }
    .roadmap-card{
        height: 268px;
    }
    .roadmap-card > p{
        height: 144px;
        overflow: hidden;
    }
}
@media (max-width: 1366px) {
    .roadmap-road {
        position: absolute;
        bottom: -119px;
        left: 12px;
    }
    .roadmap-card .roadmap-stick {
        position: absolute;
        left: -6px;
        top: 25%;
    }
    .roadmap-area-list .col-lg-3:nth-child(3) .roadmap-card .roadmap-stick {
        top: 44%;
    }
    .roadmap-area-list .col-lg-3:nth-child(2) .roadmap-card .roadmap-stick {
        top: 36%;
    }
   
}
@media (max-width: 1280px) {
    .banner-area {
        padding: 230px 0 100px;
    }
    .cubeshape h1 a {
        padding: 20px 0px;
    }
    .cubeshape h1 {
        font-size: 18px;
    }
    .first_cube, .second_cube,.cubeshape{
        height: 65px;
    }
    .social-share-buttons{
        margin-top: 0;
        margin-bottom: 30px;
    }
    .banner-person {
        position: absolute;
        right: 1%;
        bottom: 0;
        width: 57%;
        z-index: -1;
    }
    .cummunity-image {
        position: absolute;
        top: 36px;
        left: 31px;
        width: 357px;
    }
    .cominity-content-wrapper {
        margin-top: -40px;
    }
    .image-gembet-raf {
        margin-top: -40px;
    }
    .gambet-card {
        height: 400px;
    }
    .centerd-what-image{
        position: absolute;
        width: 230px;
    }
}
@media (max-width: 1024px) {

    
    .banner-area {
        position: relative;
        z-index: 10;
        padding: 214px 0 100px;
        height: 100vh;
        overflow: hidden;
    }

    .banner-person {
        position: absolute;
        right: -27%;
        bottom: 0;
        width: 79%;
    }

    .gambet-area {
        padding-bottom: 50px;
    }

    .gambet-card {
        height: 300px;
        padding: 51px 0 0;
    }

    .gambet-card h4 {
        font-size: 16px;
        height: 36px;
    }
    .gambet-row-wrapper {
        margin-top: -18px;
    }
    .gambet-top-space {
        margin-top: 100px;
    }
    .grid-odds {
        padding-top: 30px;
        padding-bottom: 40px;
    }
    .image-gembet-raf {
        margin-top: 50px;
    }
    .odds-card h4 {
        font-size: 26px;
    }

    .section-title-area h2 {
        font-size: 38px;
    }

    .diff-grad-color {
        font-size: 24px;
    }

    .shadow-text {
        font-size: 100px;
    }

    .section-title-area.small-shadow-space {
        padding-bottom: 50px;
    }

    .section-title-area.shadow-space {
        padding-bottom: 60px;
    }

    .cummunity-image {
        width: 325px;
    }
    .def-image {
        right: 4%;
        bottom: -142px;
        width: 55%;
    }
    .faq-title .gradientText {
        font-size: 24px;
    }
    .shapes-title-area img {
        margin-top: 13px;
    }
    .cm-box-1 {
        width: 55%;
        margin-right: 130px;
    }

    .cm-box-2 {
        width: 50%;
        margin-right: 97px;
    }

    .cm-box-3 {
        width: 50%;
    }

    .cm-title {
        bottom: 36px;
        left: 0;
        width: 377px;
        font-size: 40px;
    }

    .roadmap-card {
        padding: 25px 25px;
        height: 238px;
    }
    .roadmap-road {
        position: absolute;
        bottom: -177px;
        left: 12px;
    }
    .roadmap-card h4.gradientText {
        font-size: 22px;
    }

    .roadmap-card>p {
        font-size: 14px;
    }

    .roadmap-area-list .col-lg-3:nth-child(3) .roadmap-card {
        margin-top: 67px;
    }

    .roadmap-area-list .col-lg-3:nth-child(4) .roadmap-card {
        margin-top: 0;
    }

    .roadmap-area-list .col-lg-3:nth-child(2) .roadmap-card {
        margin-top: 85px;
    }

    .roadmap-area-list {
        margin-top: -52px;
    }

    .faq-area .section-title-area img {
        width: 95px;
        height: auto;
        margin-left: 48px;
    }

    .footer-image {
        width: 40%;
    }

    .footer-sec-area {
        padding-top: 100px;
        height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
    }

    .copyright-area {
        flex-direction: column;
        text-align: center;
        gap: 10px;
    }
}

@media (max-width: 991px) {
    .centerd-what-image{
        position: static;
        transform: none;
        text-align: center;
        margin: 0;
        width: 250px;
        margin-bottom: 30px;

    }
    .small-reverse-card.odds-card{
        margin-bottom: 30px;
    }
    .banner-person {
        position: absolute;
        right: -19%;
        bottom: 0;
        width: 87%;
    }
    .banner-gambet-title {
        width: 300px;
    }

    .banner-desc {
        font-size: 20px;
    }

    .btn-gradient-bg {
        padding: 14px 39px 13px;
    }

    .bordered-btn-bg {
        padding: 11px 31px;
    }

    .gambet-card h4 {
        font-size: 16px;
        margin-bottom: 20px;
    }

    .gambet-card {
        height: 300px;
    }

    .odds-card {
        height: auto;
        margin-bottom: 24px;
    }

    .diff-grad-color {
        font-size: 26px;
    }

    .section-title-area.small-shadow-space {
        padding-bottom: 84px;
    }

    .cummunity-image {
        position: static;
        width: 305px;
        margin-bottom: 42px;
    }
    .cm-box-1 {
        width: 100%;
        margin-right: 0;
        order: 2;

    }

    .cm-box-2 {
        width: 80%;
        margin-right: 0;
        order: 3;
    }

    .cm-box-3 {
        width: 70%;
        margin-right: 0;
        order: 4;
    }

    .cm-layout {
        margin-top: 20px;
    }

    .cm-layout .cm-title {
        order: 1;
        top: -50%;
        right: 0;
        left: auto;
        width: 319px;
        bottom: auto;
    }

    .shapes-title-area .gradientText {
        font-size: 24px;
    }

    .shadow-text {
        font-size: 80px;
    }

    .shadow-text.small-shadow {
        font-size: 80px;
    }

    .roadmap-area-list .col-lg-3:nth-child(1) .roadmap-card,
    .roadmap-area-list .col-lg-3:nth-child(2) .roadmap-card,
    .roadmap-area-list .col-lg-3:nth-child(3) .roadmap-card,
    .roadmap-area-list .col-lg-3:nth-child(4) .roadmap-card {
        margin-top: 0;
        margin-bottom: 30px;
    }

    .roadmap-area-list {
        margin-top: -30px;
    }

    .roadmap-card .roadmap-stick {
        left: -4px;
        top: 0;
        height: 100%;
    }

    .roadmap-area-list .col-lg-3:nth-child(2) .roadmap-card .roadmap-stick,
    .roadmap-area-list .col-lg-3:nth-child(3) .roadmap-card .roadmap-stick,
    .roadmap-area-list .col-lg-3:nth-child(4) .roadmap-card .roadmap-stick {
        top: 0;
    }

    .roadmap-road {
        display: none;
    }
    .client-logos-area {
        padding: 150px 0 50px;
    }
    .shapes-title-area{
        margin-bottom: -30px;
    }
    .shapes-title-area .gradientText {
        font-size: 19px;
    }
    .shapes-title-area img {
        margin-top: 9px;
    }
    .roadmap-card{
        height: auto;
    }
    .roadmap-card > p{
        height: auto;
    }
    .cubeshape h1 {
        font-size: 14px;
        font-weight: lighter;
    }
    .cubeshape h1 a {
        padding: 15px 0px;
    }
    .first_cube, .second_cube{
        height: 55px;
    }
    .cubeshape{
        height: 55px;
    }

    /* .social-share-buttons li a{
        padding: 22px 0;
        font-size: 14px;
    } */
}


@media (max-width:767px) {
    .small-reverse-card.odds-card {
        height: auto;
        flex-direction: column-reverse;
    }
    .ref-btn {
        display: inline-flex;
        width: 34px;
        height: 34px;
    }
    .banner-person {
        position: static;
        width: 100%;
        margin-top: 40px;
    }
    img.footer-logo {
        margin-bottom: 20px;
    }
    
    .header-menu .nav-link {
        margin: 0;
    }

    .banner-area {
        padding: 100px 0 0;
        height: auto;
    }

    .banner-gambet-title {
        width: 200px;
    }

    .footer-links-list a {
        font-size: 16px;
    }

    .banner-desc {
        font-size: 16px;
        width: 83%;
    }

    .gambet-area {
        padding-top: 60px;
        padding-bottom: 40px;
    }

    .shadow-text {
        top: 0;
        left: 50%;
        font-size: 55px;
    }

    .image-fidd {
        width: 80%;
    }

    .section-title-area.shadow-space {
        padding-bottom: 30px;
    }

    .gambet-top-space {
        margin-top: 0;
    }

    .gambet-card h4 {
        font-size: 16px;
        margin-bottom: 0;
        height: auto;
    }

    .gambet-card {
        height: auto;
        margin-bottom: 48px;
        padding: 34px 0 0;
        margin-bottom: 24px;
        height: 220px;
    }

    .gambet-card img {
        width: 67%;
    }

    .gambet-row-wrapper {
        margin-top: 0;
    }

    .grid-odds {
        padding-top: 0;
        padding-bottom: 0;
    }

    .odds-card {
        height: auto;
        flex-direction: column-reverse;
        margin-bottom: 24px;
        text-align: center;
        align-items: center;
        padding: 24px;
    }

    .odds-card h4 {
        margin-top: 20px;
    }

    .olds-content {
        margin: 0;
    }

    .howwe-area {
        padding-top: 30px;
        padding-bottom: 0;
    }

    .diff-grad-color {
        font-size: 18px;
        line-height: 1.4;
    }

    .section-title-area.small-shadow-space {
        padding-bottom: 45px;
    }

    .community-area {
        height: auto;
        padding-top: 70px;
    }
    .cummunity-image{
        width: 67%;
    margin-bottom: 0;
    }
    .cominity-content-wrapper {
        margin-top: 0;
    }
    .cm-layout .cm-title {
        position: static;
        width: 100%;
        margin-bottom: 24px;
        font-size: 24px;
        text-align: center;
    }

    .shadow-text {
        top: 0;
    }

    .cm-box-2 {
        width: 100%;
        margin-right: 0;
    }

    .cm-box-3 {
        width: 100%;
        margin-right: 0;
    }

    .def-sec-box {
        padding: 24px;
    }

    .client-logos-area {
        padding: 60px 0 50px;
    }

    .shapes-title-area img {
        display: none
    }

    .shapes-title-area .gradientText {
        margin-bottom: 30px;
    }

    .roadmap-area {
        height: auto;
        padding-top: 20px;
    }

    .shadow-text.small-shadow {
        font-size: 38px;
    }

    .section-title-area.small-shadow-space {
        padding-top: 10px;
        padding-bottom: 26px;
    }

    .roadmap-area-list {
        margin-top: 0;
    }

    .faq-area {
        margin-top: 0;
    }

    .roadmap-road {
        display: none;
    }

    .roadmap-shadow {
        top: 0;
        left: 0;
        width: 100%;
    }

    .btn-gradient-bg {
        padding: 14px 39px 13px;
        margin-bottom: 20px;
    }
}