@font-face {
    font-family: 'Azonix';
    src: url('assets/fonts/AzonixRegular.woff2') format('woff2'),
        url('assets/fonts/AzonixRegular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('assets/fonts/Poppins-Light.woff2') format('woff2'),
        url('assets/fonts/Poppins-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('assets/fonts/Poppins-Regular.woff2') format('woff2'),
        url('assets/fonts/Poppins-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}



.table-area {
    padding-top: 100px;
}

table {
    border-collapse: separate;
    border: 3px solid rgba(0, 0, 0, 0.16);
    border-radius: 10px;
    width: 100%;
}

th {
    font-size: 18px;
}

td {
    font-size: 16px;

}

th,
td {
    padding: 16px 10px;
    text-align: center;
}

.striped tbody tr:nth-child(odd) {
    background: rgba(0, 0, 0, 0.16);
}

.striped {
    background-image: url(/public/img/tablebg.png);
    background-size: 100% 100%;
    overflow: hidden;
    border-radius: 14px;
    /* margin: 0 !important; */
    border-collapse: collapse;
    border: 0;
}

.deftable-shape {
    position: absolute;
    top: -80%;
    height: auto;
    width: 80%;
    right: 0;
    z-index: 0;
}


.striped tbody td {
    display: collumn;
}

.striped tbody td img {
    width: 20px;
}

.striped thead {
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0.1), transparent);
}

thead th {
    background: linear-gradient(106.21deg, #FFFFFF 1.83%, #FF59CE 30.45%, #477FFF 62.68%, #FFFFFF 97.25%);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
}
table.striped.text-center.main-border{
    border: 2px solid rgba(255,255,255, 0.1);
    border-collapse: separate;
}
tbody th.gradient {
    background: linear-gradient(106.21deg, #FFFFFF 1.83%, #FF59CE 30.45%, #477FFF 62.68%, #FFFFFF 97.25%);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
}
.shape-imagetable {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    height: 100%;
    z-index: -1;
}

.table-wrapper {
    overflow: hidden;
    border-radius: 16px;
    z-index: 3;
}

.table-area p {
    font-family: 'Poppins';
    font-size: 20px;
    color: #fff;
    margin-top: 10px;
}

.table-area .section-title-area h3 {
    z-index: -1;
    margin-top: -50px;
}


.table-area .section-title-area h2 {
    z-index: 3;
}

.about-us-main {
    margin-top: 150px;
}

.about-us-main p {
    font-size: 20px;
    font-family: 'Poppins';
    font-weight: 100;
}

.Tokenomics-area h2,
.totalspply-area h2 {
    z-index: 3;
    margin-top: 20px;
}

.Tokenomics-area h3,
.totalspply-area h3 {
    z-index: -1;
    margin-top: -40px;
}

.Tokenomics-area img,
.totalspply-area img {
    margin-top: 4rem;
}




/* responsiv */
@media (max-width: 1440px) {
    .Tokenomics-area img,
    .totalspply-area img {
        margin-top: 3.4rem;
    }
    .Tokenomics-area,
    .totalspply-area{
        padding-top: 93px;
    }
}
@media (max-width:1024px) {
    .Tokenomics-area h3,
    .totalspply-area h3 {
        z-index: -1;
        font-size: 100px;
        margin-top: -30px;
    }
}

@media (max-width:991px) {
    .def-sec-box {
        width: 60%;
    }
    .def-image {
        position: absolute;
        right: 0;
        bottom: -77px;
        width: 48%;
        height: auto;
    }
    h2 .gradientText {
        font-size: 48px;
    }
    .section-title-area h2 {
        font-size: 34px;
    }
}

@media (max-width:767px) {
    .about-us-main {
        margin-top: 110px;
    }
    .section-title-area h2 {
        font-size: 34px;
        text-transform: capitalize;
    }

    .about-us-main p {
        font-size: 20px;
        font-family: 'Poppins';
        font-weight: 100;
    }

    .table-wrapper {
        overflow-x: auto;

    }

    .table-area {
        padding-top: 80px;
    }



    .striped thead td {
        font-size: 12px;
    }

    .striped thead th {
        font-size: 12px;
    }

    .striped thead th img {
        width: 60px;
    }

    .Tokenomics-area h3,
    .totalspply-area h3 {
        z-index: -1;
        font-size: 95px;
        margin-top: -70px;
    }

    .faq-area .section-title-area img {
        width: 80px;
        margin-left: 10px;
    }

    .defi-area {
        padding-top: 24px;
    }

    .def-sec-box {
        padding: 15% 8% 5% 8%;
        width: 100%;
    }

    .def-image {
        position: static;
        width: 100%;
        bottom: 0;
        right: 0;
    }

    .accordion-item .accordion-header .accordion-button {
        font-size: 14px;
    }


    .accordion-body {
        font-size: 14px;
    }

    .Tokenomics-area,
    .totalspply-area {
        padding-top: 20px;
        padding-bottom: 0;
        margin-top: 50px;
    }

    body .fp-overflow {
        overflow: unset;
        max-height: unset;
    }

    body.fp-scrollable.fp-responsive .section.fp-section.fp-table.fp-is-overflow {
        height: auto;
        min-height: unset;
        max-height: unset;
        overflow: unset;
    }

    img.header-logo {
        height: 40px;
    }

    .shape-imagetable {
        display: block;
    }

    .faq-area {
        margin-top: 40px;
    }

    .social-share-buttons {
        display: block;
    }

  
    .footer-image {
        width: 40%;
    }

    .fp-overflow {
        justify-content: flex-start;
        max-height: unset;
    }

    .footer-sec-area {
        padding-top: 80px;
        height: unset;
    }

    h2 .gradientText {
        font-size: 30px;
    }

    .table-td {
        display: block;
    }

    .striped {
        border-radius: 10px;
    }

    .Tokenomics-area {
        padding-top: 18px;
        padding-bottom: 50px;
    }
    .totalspply-area{
        margin-top: 0;
        padding-bottom: 50px;
    }

    .first_cube{
        border-bottom: 1px solid #272727;
    }
    .shapes-title-area .gradientText {
        margin-bottom: 30px;
    }
    .shapes-title-area {
        justify-content: center;
        margin-bottom: 0;
    }
    .cm-layout .cm-title{
        text-align: left;
    }
    .image-gembet-raf {
        margin-top: 10px;
    }
    footer {
        margin-top: 40px;
    }
    
}

@media (max-width:426px) {
    .section-title-area h2 {
        font-size: 34px;
        text-transform: capitalize;
    }

    .about-us-main p {
        font-size: 16px;
        font-family: 'Poppins';
        font-weight: 100;
    }


    .striped thead th {
        font-size: 12px;
    }

    .faq-area .section-title-area .gradientText {
        font-size: 30px;
    }

    .faq-area .section-title-area img {
        width: 60px;
        margin-left: 10px;
    }

    .Tokenomics-area h3 {
        z-index: -1;
        font-size: 50px;
        margin-top: -16px;
    }

    .Tokenomics-area img {
        margin-top: 30px;
    }

    .striped thead th {
        font-size: 12px;
    }

    .striped tbody th {
        font-size: 12px;
    }

    .striped tbody td {
        font-size: 12px;
    }

    .table-area .section-title-area h3 {
        z-index: -1;
        margin-top: -35px;
    }

    .def-sec-box h2 {
        font-size: 24px;
        padding: 0;
    }

    .def-sec-box p {
        font-size: 16px;
    }

    .table-area {
        padding-top: 80px;
    }

    .table-area p {
        font-family: 'Poppins';
        font-size:16px;
        margin-top: 10px;
    }

    .def-image {
        position: relative;
        width: 100%;
        bottom: 0;
        right: 0;
    }

    .accordion-item .accordion-header .accordion-button {
        font-size: 12px;
    }


    .accordion-body {
        font-size: 14px;
    }

   

    .faq-area {
        padding: 0;
    }

    .social-share-buttons {
        display: block;
    }

    .striped tbody td .table-td {
        display: block;
    }


}

@media (max-width:320px) {
    .section-title-area h2 {
        font-size: 20px;
        text-transform: capitalize;
    }

    .about-us-main p {
        font-size: 14px;
        font-family: 'Poppins';
        font-weight: 100;
    }

 

    .table-area p {
        font-size: 14px;
    }


    .striped thead th {
        font-size: 12px;
    }

    .striped tbody th {
        font-size: 12px;
    }

    .striped tbody td {
        font-size: 10px;
    }

    .Tokenomics-area img {
        margin-top: 30px;
    }

    .Tokenomics-area h3 {
        z-index: -1;
        font-size: 40px;
        margin-top: -35px;
    }

    .faq-area .section-title-area .gradientText {
        font-size: 30px;
    }

    .faq-area .section-title-area img {
        width: 50px;
        margin-left: 10px;
    }

    .table-area .section-title-area h3 {
        z-index: -1;
        margin-top: -17px;
    }

    .table-area {
        padding-top: 80px;
    }


    .def-image {
        position: relative;
        width: 100%;
        bottom: 0;
        right: 0;
    }

    .def-sec-box h2 {
        font-size: 20px;
        padding: 0;
    }

    .def-sec-box p {
        font-size: 14px;
    }

    .def-sec-box {
        padding: 16% 8% 16% 8%;
    }

    .accordion-item .accordion-header .accordion-button {
        font-size: 10px;
    }

    .accordion-body {
        font-size: 14px;
    }

    .social-share-buttons {
        display: block;
    }

}